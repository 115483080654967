.btn {
    font-size: $fz-button-desktop;

    &-small {
        @include padding(8px 25px);
    }

    &--max-width {
        max-width: $mw-button;
        @include margin(null auto);
    }
}
