.system-picker {
    background-color: transparent;
    border-left: 1px solid $body-bg;
    border-right: 1px solid $body-bg;
    float: left;
    min-height: $h-header-desktop;
    position: relative;
    max-width: $w-system-picker-desktop;

    @media (max-width: $screen-lg-max) {
        max-width: $w-system-picker-desktop-lg;
    }

    @media (min-width: 1450px) {
        max-width: $w-system-picker-desktop-xlg;
    }

    &__text {
        max-width: 100%;
        padding-left: 45px;

        @media (max-width: $screen-lg-max) {
            padding-left: 45px;
        }

        > span {
            display: block;
            @include text-overflow();
        }
    }

    &__status-online {
        max-width: 100%;
        font-size: 10px;
        color: #3c763d;
        font-weight: bold;
    }

    &__status-offline {
        max-width: 100%;
        font-size: 10px;
        color: #f1204c;
        font-weight: bold;
    }

    &__item {
        border-bottom: 0;
        min-height: $h-header-desktop;
    }

    &__link {
        @include padding(9px 20px 16px);

        svg {
            position: absolute;
            margin-top: 3px;
        }

        @media (max-width: $screen-lg-max) {
            @include padding(null 10px);

            &--action {
                padding-right: 40px;
            }

            svg {
                margin-right: 5px;
            }
        }

        &--action {
            padding-right: 50px;
        }

        &--link-only {
            padding-right: 20px;
        }
    }

    &--link {
        .system-picker__link {
            &:after {
                background-size: 14px 8px;
                right: 20px;
                margin-top: -4px;

                @media (max-width: $screen-lg-max) {
                    right: 15px;
                }
            }
        }
    }

    &__action {
        right: 5px;

        @media (max-width: $screen-lg-max) {
            right: 5px;
        }
    }

    &__list {
        border-radius: 0 0 4px 4px;
        border-top: 0;
        overflow: hidden;
        max-width: 400px;
        min-width: 100%;
        width: auto;
        @include box-shadow($bxsh-content-desktop);
        //@include padding(0 null);

        .system-picker__text {
            > span {
                white-space: normal;
            }
            &--one-line {
                &,
                > span {
                    white-space: nowrap;
                }
            }
        }
    }
}
