.header {
    border-bottom: 1px solid $bb-header-desktop;
    height: $h-header-desktop;
    position: static;
    @include padding(0 null);
    @include box-shadow(none);

    @media (max-width: $screen-xs-max) {
        @include position(static, null null null null);
    }

    .content & {
        background-color: $bg-header-desktop;
        height: auto;
        @include border-top-radius($border-radius-base);
        @include padding(20px 30px);
    }

    .container-fluid {
        padding-bottom: 0;
    }

    h1 {
        padding-left: 15px;
        text-align: left;
    }
}
