html,
body {
    min-width: $w-page-min-desktop;
    min-height: 100%;
}

body {
    text-align: left;
}

.wrapper {
    min-height: 100%;
    position: relative;

    .popup-visible & {
        height: 100%;
        overflow: hidden;
    }
}

.hide-desktop,
.nav > li.hide-desktop {
    display: none;
}

.top-margin {
    margin-top: 50px;
}

.bottom-margin {
    margin-bottom: 50px;
}

.content-wrapper {
    @include padding($p-content-desktop);

    @media (max-width: $screen-lg-max) {
        @include padding($p-content-desktop-lg);
    }

    > .content {
        background-color: $bg-content-desktop;
        border-radius: $border-radius-base;
        max-width: 1200px;
        @include box-shadow($bxsh-content-desktop);

        .content {
            @include padding(15px 20px);
        }
    }
}
