.user-menu {
    float: right;
    @include padding(0);

    li {
        float: left;
    }

    &__link {
        border-left: 1px solid $body-bg;
        color: $c-side-menu-icon-desktop;

        svg {
            margin-right: 0;
            @include size($w-side-menu-icon-desktop, $h-side-menu-icon-desktop);
        }
    }

    &__link-text {
        display: none;
    }
}
