.left-menu {
    background-color: $bg-left-menu-desktop;
    width: $w-left-menu-desktop;
    @include position(absolute, $h-header-desktop null 0 0);

    @media (max-width: $screen-lg-max) {
        width: $w-left-menu-desktop-lg;
    }

}
