.user-block {
    @extend .user-menu__link;
    background-color: transparent;
    border-bottom: 0;
    border-left: 0;
    float: right;
    max-width: 300px;
    @include padding(24px 25px);

    @media (max-width: $screen-lg-max) {
        max-width: 185px;
        @include padding(null 15px);
    }

    &__link-text {
        display: inline-block;
        max-width: 78%;
        @include text-overflow();
    }

    svg {
        margin-right: 15px;

        @media (max-width: $screen-lg-max) {
            margin-right: 5px;
        }
    }
}
