.react {
    float: left;
    min-height: $h-header-desktop;
    @include padding(20px 30px);

    @media (max-width: $screen-lg-max) {
        @include padding(null 15px);
    }

    .btn {
        font-size: $font-size-base;
        @include padding(8px 25px);

        @media (max-width: $screen-lg-max) {
            @include padding(null 15px);
        }
    }

    svg {
        @include size(21px, 24px);
        @include margin(-5px 7px -3px null);
    }

    &__link-text {
        @extend .user-menu__link-text;
    }
}
