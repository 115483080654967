.form-control {
    font-size: $fz-input-desktop;
    height: $input-height-base-desktop;
    border-radius: $input-border-radius-desktop;
}

input[type="file"].form-control {
    @include padding(0 null);
}

label {
    font-size: $fz-label-desktop;
}

.select2-container {
    &--default {
        .select2-selection--single {
            border-radius: $input-border-radius-desktop;

            .select2-selection__rendered {
                font-size: $fz-input-desktop;
                padding-right: 40px;
            }
            .select2-selection__arrow {
                background-size: 14px 8px;
                margin-top: -3px;
                right: 20px;
                @include size(14px, 8px);
            }
        }

        .select2-results__option {
            font-size: $fz-input-desktop;
        }
    }
}

.form__wrapper {
    width: 280px;
    @include margin(null auto);
    &-md {
        width: 380px;
    }
}

.checkbox-block {
    + .checkbox-block {
        margin-top: 15px;
    }

    > input {
        + label {
            font-size: $fz-checkbox-label-desktop;
            padding-top: 4px;
        }
    }
}

.errors {
    &--info {
        .list + & {
            margin-top: 30px;
        }
    }
}

.form-group {
    .auth & {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &:last-of-type {
        margin-bottom: $form-group-margin-bottom;
    }
}
